import React from 'react'
import ContentBlocks from '../components/homepage/contentBlocks'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Seo from '../components/utils/seo'
import { InlineWidget } from "react-calendly"

const InternalPage = ({ data }) => {
    const title = data.sanityPage.seo?.title
    const description = data.sanityPage.seo?.description
    const image = data.sanityPage.seo?.image?.asset.url
    const slug = data.sanityPage.slug.current
    return (
        <Layout>
            <Seo title={title} description={description} image={image} />
            <div className='max-w-screen-xl mx-auto md:my-12 px-8 min-h-full'>
                <h1 className='text-5xl md:text-6xl text-center mb-12'>{data.sanityPage.title}</h1>
                <ContentBlocks contentBlocks={data.sanityPage.contentBlocks} _rawContentBlocks={data.sanityPage._rawContentBlocks} />
            </div>
            {/*
            {slug === 'book-appointment' ? 
            <InlineWidget
            iframeTitle="Calendly Scheduling Page"
            pageSettings={{
              backgroundColor: 'ffffff',
              primaryColor: '000000',
              textColor: '000000'
            }}
            styles={{
              height: '1200px',
              focus: 'none'
            }}
            url="https://calendly.com/rosyherbalist"
          />
            :
          null}*/}
        </Layout>
    )
}

export default InternalPage

export const query = graphql`
    query sanityPageQuery($slug: String) {
        sanityPage(slug: {current: {eq: $slug}}) {
            title
            slug {
              current
            }
            ...PageContentBlocksFragment
            seo {
                title
                description
                image {
                    asset {
                        url
                    }
                }
            }
          }
    }
`
